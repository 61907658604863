
.CampaignItem {
    padding-top: 2px;
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 120px;
}

.title {
    margin-bottom: 28px;
    font-size: 22px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    line-height: 26px;
}

.label {
    font-size: 12px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.phone {
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.keyword {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.link {
    display: block;
    margin-top: 36px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s opacity;
}
.deleteButton {
    cursor: pointer;
    color: rgb(51, 51, 51);
    width: 130px;
    text-align: center;
    margin-top: 12px;
    background-color: rgb(158, 0, 0);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 6px 8px;
    font-weight: normal;
}

.link:hover {
    opacity: 0.7;
}

@media (min-width: 800px) {
    .CampaignItem {
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        min-height: 200px;
    }
}