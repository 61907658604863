
.page {
    /* margin-top: 16px; */
    height: 100%;
    overflow: scroll;
}

.content {
    /* margin-top: 8px; */
    /* padding-bottom: 16px; */
    height: 100%;
}

.steps {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.description {
    margin-top: 28px;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 24px;
    text-align: center;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.done {
    margin-top: 12px;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    color: var(--appearance-h1, transparent);
    background-color: var(--appearance-b1, transparent);
    text-align: center;
    cursor: pointer;
}


.messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
    padding-right: 16px;
}

.keywords {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
}

.contacts {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;

}

.contact {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.icon {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/chat_delete.svg');
    opacity: 0.0;
    cursor: pointer;
    transition: 0.3s opacity;
}

.container:hover .icon {
    opacity: 0.5;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.addContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.add {
    margin-top: 20px;
    margin-bottom: 20px;
}
