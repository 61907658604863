
.OptionMenu {
    position: fixed;
    top: -1000px;
    left: -1000px;
    right: -1000px;
    bottom: -1000px;
    padding: 1000px;
    box-sizing: border-box;
    /* background-color: rgba(248, 246, 242, 0.5); */
    background-color: rgba(128, 128, 128, 0.5);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(-12px);
    transition: 0.3s opacity, 0.3s transform ease;
}

.OptionMenu.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.noMobile {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none !important;
    opacity: 0.0001;
    background-color: rgba(255, 255, 255, 0.822);
}

@media (min-width: 900px) {
    .noMobile {
        display: block !important;
    }
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    height: 100%;
    padding-top: calc(20px + env(safe-area-inset-top));
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.option {
    position: relative;
    display: flex;
    padding: 12px 24px;
    width: 200px;
    box-sizing: border-box;
    /* border-radius: 12px; */
    background-color: rgb(248, 246, 242);
    cursor: pointer;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.option:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.option:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: none;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    opacity: 0.5;
}

.text {
    font-size: 14px;
}