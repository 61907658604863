
.BillingDialog {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 24px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: rgba(128, 128, 128, 0.3);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    /* transform: translateY(50px); */
    transition: 0.3s opacity, 0.3s transform ease;
}

.BillingDialog.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    max-width: 380px;
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
    /* padding-bottom: 32px; */
    border-radius: 16px;
    background-color: rgb(248, 246, 242);
}

.title {
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    line-height: 26px;
    letter-spacing: -1px;
    text-align: center;
}

.card {
    display: flex;
    margin-top: 12px;
}

.info {
    display: flex;
    margin-bottom: 12px;
}

.inputLeft {
    margin-right: 10px;
}

.inputMiddle {
    margin-left: 5px;
    margin-right: 5px;
}

.inputRight {
    margin-left: 10px;
}

.shop {
    margin-left: auto;
    margin-right: auto;
    width: 60px;
    height: 60px;
    background-size: 60px 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/modal_shop.svg');
    opacity: 0.8;
}

.description {
    margin-top: 8px;
    margin-bottom: 12px;
    /* max-width: 320px; */
    /* font-size: 15px; */
    /* font-weight: 600; */
    color: rgba(51, 51, 51, 0.5);
    /* line-height: 24px; */
    text-align: center;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
}

.actions {
    display: flex;
    margin: 0px -8px;
    margin-top: 24px;
    justify-content: space-between;
}

.done {
    margin-top: 12px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.7);
    line-height: 24px;
    text-align: center;
    cursor: pointer;
}
