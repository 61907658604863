
.title {
    margin-bottom: 4px;
    font-size: 28px;
    font-weight: 400;
    color: rgb(51, 51, 51);
}

.description {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.7);
    line-height: 21px;
}
