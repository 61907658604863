

.CampaignItem {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.05); */

    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    /* background-color: brown; */
    padding-top: 16px;
    padding-bottom: 16px;
    /* margin-bottom: 16px; */
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.05); */
    border-radius: 20px;
    padding: 20px 20px;
    padding-left: 0px;
    padding-right: 24px;
    box-sizing: border-box;
}

.CampaignItem.selected {
    /* background-color: rgb(255, 255, 255); */
    background-color: #4670fc;
}

.info {
    width: 100%;
}

.titleContainer {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.title {
    color: rgba(51, 51, 51, 1.0);
    font-size: 17px;
}

.date {
    font-size: 14px;
    color: rgba(51, 51, 51, 0.5);
}

.message {
    display: block;
    color: rgba(51, 51, 51, 0.5);
    line-height: 20px;
    max-height: 40px;
    margin-bottom: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}

.CampaignItem.selected .title {
    color: rgb(255, 255, 255);
}

.CampaignItem.selected .date {
    color: rgba(255, 255, 255, 0.5);
}

.CampaignItem.selected .message {
    color: rgba(255, 255, 255, 0.8);
}

.unread { 
    margin-right: 16px;
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: #3d68f3;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.unread.visible {
    opacity: 1.0;
    transform: scale(1.0);
}

/*
we gonna keep the original shit here until we can revamp these

.CampaignItem {
    padding-top: 2px;
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 120px;
}

.title {
    margin-bottom: 28px;
    font-size: 22px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    line-height: 26px;
}

.label {
    font-size: 12px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.phone {
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.keyword {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.link {
    display: block;
    margin-top: 36px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s opacity;
}
.deleteButton {
    cursor: pointer;
    color: rgb(51, 51, 51);
    width: 130px;
    text-align: center;
    margin-top: 12px;
    background-color: rgb(158, 0, 0);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 6px 8px;
    font-weight: normal;
}

.link:hover {
    opacity: 0.7;
}

@media (min-width: 800px) {
    .CampaignItem {
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        min-height: 200px;
    }
}
*/