
.topBar {
    display: flex;
    padding: 20px 20px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    margin-bottom: 24px;
}

.topBar .credits {
    display: flex;
    cursor: pointer;
}

.topBar .creditIcon {
    width: 48px;
    height: 48px;
    /* background-image: url('../../assets/nav_coins.svg'); */
    background-size: 24px 24px;
    
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V5H10.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64H8.04c.1 1.7 1.36 2.66 2.86 2.97V19h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
}

.topBar .creditText {
    margin-right: 4px;
}

.topBar .title {
    flex-grow: 1;
    margin-bottom: 4px;
    padding-left: 48px;
}

.logo {
    position: absolute;
    top: 24px;
    left: 20px;
    width: 38px;
    height: 38px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='38px' height='38px' viewBox='0 0 38 38' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard Copy 313%3C/title%3E%3Cg id='Artboard-Copy-313' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='add' transform='translate(11.000000, 11.000000)'%3E%3C/g%3E%3Cg id='T2G_Vertical_white_blue-no_tag-(1)' fill='%2300AEEF' fill-rule='nonzero'%3E%3Cpath d='M6.20349435,29 L6,38 L15,30.9457014 C12.0447071,30.3755656 9.08478931,29.8280543 6.20349435,29 Z' id='Path'%3E%3C/path%3E%3Ccircle id='Oval' cx='17' cy='17' r='17'%3E%3C/circle%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-position: left top;
    background-repeat: no-repeat;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: calc(100vh - env(safe-area-inset-top));
    /* padding-bottom: 16px; */
}

.stats {
    flex-grow: 1;
    margin-top: 16px;
    padding: 0px 16px;
    padding-bottom: 16px;
    padding-bottom: calc(env(safe-area-inset-bottom) + 52px + 20px);
    overflow: scroll;
    box-sizing: border-box;
}

.greeting {
    padding: 0px 20px;
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    margin: 8px 0px;
    margin-bottom: 24px;
}

.description {
    margin-top: 16px;
    margin-bottom: 16px;
}

.page {
    display: none;
    margin-top: 16px;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.page.display {
    display: block;
}

.page.visible {
    opacity: 1.0;
    transform: scale(1.0);
    /* display: block; */
}





/* .content {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px 20px;
} */

.box {
    border-radius: 8px;
    min-height: 120px;
    animation: entrance 0.5s forwards;
    opacity: 0.0;
    transition: 0.3s opacity;
}

.box.a { animation-delay: 0.2s; }
.box.b { animation-delay: 0.25s; }
.box.c { animation-delay: 0.3s; }
.box.d { animation-delay: 0.35s; }
.box.f { animation-delay: 0.4s; }

.box .icon {
    color: rgba(51, 51, 51, 1.0);
    margin-left: 4px;
    margin-bottom: 12px;
}

.box .title {
    font-size: 22px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.box .description {
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.5);
}
/* 
@media (min-width: 800px) {
    .content {
        margin-top: 60px;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 32px 32px;
    }

    .box {
        min-height: 200px;
    }
} */

@keyframes entrance {
    from {
        transform: scale(0.8);
        opacity: 0.0;
    }
    to {
        transform: scale(1.0);
        opacity: 1.0;
    }
}
