
.SignIn {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 1000000;
}

.container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    /* align-items: center; */
    /* height: calc(100vh - 44px); */
    padding-top: 64px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: env(safe-area-inset-bottom);
    box-sizing: border-box;
    transition: 0.5s transform, 0.5s opacity;
}

.container.pending {
    opacity: 0.5;
    pointer-events: none;
}

.info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    /* background: brown; */
}

.input {
    background: transparent;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding: 20px;
    font-size: 15px;
    color: rgba(51, 51, 51, 1.0);
}

.input::placeholder {
    color: rgba(51, 51, 51, 0.5);
}

/* .container.finished {
    opacity: 0.0;
    transform: scale(1.2);
} */

.icon {
    width: 300px;
    height: 300px;
    /* background-image: url('../../assets/icon_1024.png'); */
    background-size: cover;
    background-color: brown;
    opacity: 0.0;
    animation-name: entrance-down;
    animation-delay: 1s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}

.title {
    margin-top: 24px;
    margin-bottom: 4px;
    max-width: 260px;
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    color: rgba(51, 51, 51, 1.0);
    /* text-align: center; */
    opacity: 0.0;
    animation-name: entrance-up;
    animation-delay: 1.2s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}

.description {
    margin-top: 8px;
    margin-bottom: 64px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3em;
    color: rgba(51, 51, 51, 0.4);
    opacity: 0.0;
    animation-name: entrance-up;
    animation-delay: 1.3s;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
}

.description a {
    color: rgba(51, 51, 51, 1.0);
    text-decoration: underline;
}

.textField {
    margin-bottom: 12px;
}

.textField:first-of-type {
    margin-top: 16px;
}

.textField:last-of-type {
    margin-bottom: 36px;
}

.options {
    width: 100%;
    padding-bottom: 20px;
}

.snap {
    color: rgba(51, 51, 51, 1.0);
    background-color: #FFD151;
}

.submit.enabled {
    /* border: 2px solid rgba(20, 210, 210, 0.9);
    color: rgba(51, 51, 51, 1.0); */
    pointer-events: auto;
}

.phoneSignIn {
    padding-left: 24px;
    padding-right: 24px;
}

.send {
    position: fixed;
    width: calc(100% - 32px);
    padding-left: 8px;
    /* padding-right: 8px; */
    box-sizing: border-box;
    /* background-color: tomato; */
    bottom: calc(env(safe-area-inset-bottom) + 72px);
}

.contacts {
    height: calc(100vh - 84px - env(safe-area-inset-bottom));
    overflow: scroll;
    padding-bottom: 96px;
    box-sizing: border-box;
}

@keyframes entrance-down {
    from {
        transform: translateY(-40px);
        opacity: 0.0;
    }
    to {
        transform: translateY(0px);
        opacity: 1.0;
    }
}

@keyframes entrance-up {
    from {
        transform: translateY(12px);
        opacity: 0.0;
    }
    to {
        transform: translateY(0px);
        opacity: 1.0;
    }
}

@keyframes entrance {
    from {
        transform: scale(0.8);
        opacity: 0.0;
    }
    to {
        transform: scale(1.0);
        opacity: 1.0;
    }
}
