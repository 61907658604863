
.SignUp {
    
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 420px; /* ;) */
    padding-top: 64px;
    padding-left: 20px;
    padding-right: 20px;
    transition: 0.5s transform, 0.5s opacity;
}

.container.pending {
    opacity: 0.5;
    pointer-events: none;
}

.container.finished {
    opacity: 0.0;
    transform: scale(1.2);
}

.title {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 28px;
    font-weight: 400;
    color: rgb(51, 51, 51);
}

.description {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3em;
    color: rgba(51, 51, 51, 0.7);
}

.description a {
    color: rgba(51, 51, 51, 1.0);
    text-decoration: underline;
}

.textField {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}

.textField:focus {
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.submit {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: rgba(51, 51, 51, 0.5);
    text-align: center;
    cursor: pointer;
    pointer-events: none;
    transition: 0.3s border-color, 0.3s color;
}

.submit.enabled {
    border: 2px solid rgba(20, 210, 210, 0.9);
    color: rgba(51, 51, 51, 1.0);
    pointer-events: auto;
}
