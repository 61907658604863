
.StatItem {
    /* display: flex;
    align-items: center; */
    width: 100%;
    padding: 24px 20px;
    box-sizing: border-box;
    border-radius: 16px;
    /* background-color: rgb(248, 246, 242); */
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
}

.title {
    color: rgba(51, 51, 51, 1.0);
    /* margin-bottom: 8px; */
    font-size: 17px;
}

.stat {
    /* margin-bottom: 16px; */
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 27px;
    font-weight: 400;
}

.category {
    color: rgba(51, 51, 51, 0.5);
    /* margin-bottom: 2px; */
    font-size: 14px;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: 100% 100%;
    margin-bottom: 16px;
    margin-right: 16px;
    flex-shrink: 0;
    /* opacity: 0.7; */
}

@media (min-width: 900px) {
    .StatItem {
        display: block;
    }
}