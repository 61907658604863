
.content {
    margin-top: 8px;
    padding-bottom: 52px;
}

.desktopOnly {
    flex-grow: 1;
}

.desktopOnly .content {
    margin-top: 0px;
    /* width: 500px; */
    flex-grow: 1;
}

.messages {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.message {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.message.sent {
    align-items: flex-end;
}

.time {
    width: 100%;
    color: rgba(51, 51, 51, 0.4);
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    box-sizing: border-box;
}

.time.sent {
    text-align: right;
}

.bubble {
    font-size: 15px;
    padding: 8px 16px;
    line-height: 26px;
    /* background-color: #3d68f3; */
    background-color: rgba(215, 215, 215, 0.9);
    /* background-color: rgb(239, 121, 78); */
    /* background-color: #6ac059; */
    color: rgba(51, 51, 51, 1.0);
    /* color: white; */
    border-radius: 20px;
    max-width: 260px;
    margin-bottom: 1px;
    /* border: 1px solid rgba(51, 51, 51, 0.08); */
}

.message.sent .bubble {
    color: rgb(255, 255, 255);
    background-color: #3d68f3;
    /* box-shadow: none;e */
}

.textInputContainer {
    /* display: flex; */
    position: fixed;
    min-height: 40px;
    left: 0px;
    right: 0px;
    bottom: calc(52px + env(safe-area-inset-bottom));
}

.desktopOnly .textInputContainer {
    position: absolute;
}

.textInput {
    position: relative;
    margin: 0;
    padding: 16px 20px;
    box-sizing: border-box;
    color: rgba(51, 51, 51, 1.0);
    line-height: 21px;
    /* background-color: rgba(37, 34, 43, 0.7); */
    /* rgb(248, 246, 242); */
    background-color: rgba(248, 246, 242, 0.5);
    backdrop-filter: blur(24px);
    border-bottom: none;
}

.desktopOnly .textInput {
    background-color: rgba(248, 246, 242, 0.5);
}

.desktopOnly .textInputContainer {
    position: fixed;
    right: 0px;
    bottom: 0px;
    margin-left: calc(84px + 340px);
    width: calc(100% - 84px - 340px);
}

.textInput:focus {
    border-bottom: none;
}

.textInput .placeholder {
    display: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.placeholder {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    pointer-events: none;
    padding: 16px 20px;
    box-sizing: border-box;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.5);
    line-height: 21px;
    opacity: 0.0;
}

.placeholder.visible {
    opacity: 1.0;
}

.input {
    outline: none;
    border: none;
    width: calc(100% - 44px);
    font-size: 14px;
    color: rgba(51, 51, 51, 1.0);
    line-height: 21px;
    /* position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px; */
    /* padding: 20px 20px;
    box-sizing: border-box; */
}

.input:focus {
    
}

.send {
    position: absolute;
    right: 20px;
    bottom: 0px;
    width: 52px;
    height: 52px;
    /* background-color: brown; */
    background-image: url('../../assets/chat_send.svg');
    opacity: 0.9;
}