
.ContactItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-top: 14px;
    padding-bottom: 16px;
    /* background-color: darkgray; */
    border-bottom: 1px solid rgb(48, 48, 55);
    cursor: pointer;
}

.options {
    color: rgba(51, 51, 51, 1.0);
}

.delete {
    opacity: 0.7;
    cursor: pointer;
    transition: 0.3s opacity;
}

.delete:hover {
    opacity: 1.0;
}

.name {
    margin-top: 12px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(51, 51, 51, 1.0);
}

.phone {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    color: rgb(51, 51, 51, 0.7);
}