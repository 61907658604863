.CampaignsShow {
    color: rgb(51, 51, 51);
}

.banner {
    display: none;
    width: 100%;
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 32px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: rgb(48, 48, 55);
    cursor: pointer;
}

.banner.visible {
    display: block;
}

.banner .title {
    font-size: 21px;
    font-weight: 400;
}

.banner .description {
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.7);
}

.subheading {
    font-size: 18px;
    font-weight: bold;
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}

.deleteButton {
    cursor: pointer;
    color: rgb(51, 51, 51);
    background-color: rgb(158, 0, 0);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: normal;
}