
.CampaignAdd {
    
}

.SignIn {
    
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 720px; /* ;) */
    padding-top: 24px;
    padding-left: 20px;
    padding-right: 20px;
    transition: 0.5s transform, 0.5s opacity;
}

.container.pending {
    opacity: 0.5;
    pointer-events: none;
}

.container.finished {
    opacity: 0.0;
    transform: scale(1.2);
}

.title {
    margin-top: 24px;
    margin-bottom: 4px;
    font-size: 28px;
    font-weight: 400;
    color: rgb(51, 51, 51);
}

.description {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3em;
    color: rgba(51, 51, 51, 0.7);
}

.description a {
    color: rgba(51, 51, 51, 1.0);
    text-decoration: underline;
}

.textField {
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
    resize: none;
}

.textField:focus {
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.counter {
    display: inline-block;
    flex-grow: 1;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.7);
    text-align: right;
}

.tokensDescription {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.7);
}

.legalInfo {
    display: flex;
    margin-top: 8px;
    margin-bottom: 40px;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.4em;
    color: rgba(51, 51, 51, 0.7);
}

.textField.fade {
    opacity: 0.5;
}

.textField.includeLegal {
    opacity: 1.0;
}

.checkContainer {
    margin-left: 24px;
}

.checkText {
    margin-top: 8px;
}

.tokens {
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 24px;
}

.token {
    display: inline-block;
    margin-right: 12px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: rgba(51, 51, 51, 1.0);
    font-size: 15px;
    font-weight: 600;
    padding: 12px;
    cursor: pointer;
    transition: 0.3s border;
}

.emoji {
    margin-right: 8px;
    font-size: 21px;
    cursor: pointer;
}

.token:hover {
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.submit {
    margin-top: 12px;
    margin-bottom: 12px;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: rgba(51, 51, 51, 0.5);
    text-align: center;
    cursor: pointer;
    pointer-events: none;
    transition: 0.3s border-color, 0.3s color;
}

.submit.enabled {
    border: 2px solid rgba(20, 210, 210, 0.9);
    color: rgba(51, 51, 51, 1.0);
    pointer-events: auto;
}
.check {
    width: 44px;
    height: 44px;
    border: 1px solid green;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.check.includeLegal {
    /* border: 2px solid rgba(255, 255, 255, 0.5); */
    background-image: url('../../assets/check.svg');
    background-position: center;
    background-repeat: no-repeat;
}
.medias{
    height: 150px;
    width: 150px;
}
.row{
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    
}
.rows{
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.boxes{
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 16px;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
  }
  .options{
    background-color:#181a21;
    color: rgb(51, 51, 51);
    border-radius: 5px;
}