
.labels {
    display: flex;
    width: 100%;
    height: 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 44px;
    color: rgba(51, 51, 51, 0.7)
}

.label {
    flex-grow: 1;
    flex-basis: 0;
    font-size: 15px;
    flex-grow: 1;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.button {
    cursor: pointer;
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: normal;
    border: 2px solid white;
    color: rgb(51, 51, 51);
    background: transparent;
    text-align: center;
}

.disabledButton {
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: normal;
    border: 2px solid white;
    color: rgb(51, 51, 51);
    background: transparent;
    text-align: center;
    opacity: 0.5;
}
