
.Button {
    position: relative;
    width: 100%;
    height: 50px;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    border-radius: 12px;
    background-color: rgb(82, 211, 110);
    cursor: pointer;
    box-sizing: border-box;
    color: white;
    transition: 0.3s opacity ease;
}

.Button.post {
    background-color: rgb(82, 211, 110);
}

.Button.disabled {
    /* opacity: 0.5; */
    background: #2c47a0;
    pointer-events: none;
}

.label {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    color: rgba(51, 51, 51, 1.0);
    color: white;
    text-align: center;
    transition: 0.3s transform ease, 0.3s opacity ease;
}

.check {
    position: absolute;
    top: 7px;
    right: 16px;
    width: 36px;
    height: 36px;
    /* background-image: url('../../assets../../assets/share_down.svg'); */
}
