
.addNew {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgb(126, 121, 143);
    /* backdrop-filter: blur(24px); */
    z-index: 10;
}

.box {
    width: 100px;
    height: 140px;
    background: red;
    margin-bottom: 20px;
}


.input {
    background: transparent;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    padding: 0px 0px;
    font-size: 15px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
}

.input::placeholder {
    color: rgba(51, 51, 51, 0.5);
}

.content {
    /* margin-top: 12px; */
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 32px;
    min-height: 320px;
    padding-bottom: 20px;
    box-sizing: border-box;
}

.contentMedia {
    margin-top: 8px;
    box-sizing: border-box;
}

.contentConfirm {
    display: flex;
    flex-direction: column;
    /* margin-top: 8px; */
    box-sizing: border-box;
    position: relative;
    height: 100%;
}

.steps {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.container {
    width: 100%;
    /* padding: 20px; */
    /* padding-top: env(safe-area-inset-top); */
    box-sizing: border-box;
    border-radius: 16px;
    /* background-color: rgb(248, 246, 242); */
    border: 2px solid rgba(51, 51, 51, 0.1);
}

.title {

}

.description {
    margin-top: 28px;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 24px;
    text-align: center;
}

.message {
    line-height: 24px;
    height: 160px;
    padding-bottom: 24px;
}

.messageTools {
    position: relative;
    display: flex;  
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;
}

.rotateTools {
    position: relative;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin-right: 12px;
    /* width: 200px; */
}

.tokens {
    position: absolute;
    top: 2px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    display: flex;
    /* background-color: rgba(255, 255, 255, 0.05); */
    /* z-index: 500000; */
    border-radius: 20px;
    overflow-x: scroll;
    margin-right: 20px;
    opacity: 0.0;
    pointer-events: none;
    transform: translateY(-8px);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.tokens.visible {
    opacity: 1.0;
    transform: translateY(0px);
    pointer-events: auto;
}

.token {
    height: 32px;
    padding-left: 20px;
    padding-right: 20px;
    /* flex-grow: 1; */
    flex-shrink: 0;
    color: rgba(51, 51, 51, 1.0);
    background-color: rgba(255, 255, 255, 0.1);
    /* border: 1px solid rgba(255, 255, 255, 0.1); */
    z-index: 1;
    border-radius: 20px;
    margin-right: 12px;
    line-height: 32px;
    cursor: pointer;
}

.counter {
    flex-shrink: 1;
}

.media {
    display: flex;
    flex-grow: 1;
    box-sizing: border-box;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.media.hidden {
    opacity: 0.0;
    transform: translateY(8px);
}

.mediaItem {
    display: flex;
}

.attachIcon {
    width: 36px;
    height: 36px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cg%3E%3Crect fill='none' height='24' width='24'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M8,11h8v2H8V11z M20.1,12H22c0-2.76-2.24-5-5-5h-4v1.9h4C18.71,8.9,20.1,10.29,20.1,12z M3.9,12c0-1.71,1.39-3.1,3.1-3.1h4 V7H7c-2.76,0-5,2.24-5,5s2.24,5,5,5h4v-1.9H7C5.29,15.1,3.9,13.71,3.9,12z M19,12h-2v3h-3v2h3v3h2v-3h3v-2h-3V12z'/%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.7;
}

.attachLink {
    width: 36px;
    height: 36px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M18 20H4V6h9V4H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-9h-2v9zm-7.79-3.17l-1.96-2.36L5.5 18h11l-3.54-4.71zM20 4V1h-2v3h-3c.01.01 0 2 0 2h3v2.99c.01.01 2 0 2 0V6h3V4h-3z'/%3E%3C/svg%3E");
    opacity: 0.7;
}

.attachDelete {
    width: 36px;
    height: 36px;
    margin-right: 12px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/media_trash.svg');
    opacity: 0.0;
    pointer-events: none;
}

.attachDelete.visible {
    opacity: 0.7;
    pointer-events: auto;
}

.attachText {
    margin-left: 4px;
    line-height: 36px;
    max-height: 36px;
    flex-grow: 1;
    display: block;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
}

.mediaPreview {
    width: 240px;
    height: 160px;
    background-size: cover;
    background-color: brown;
    border-radius: 12px;
}

.followUpPicker {
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
    padding: 0px 16px;
    box-sizing: border-box;
}

.followUp {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px 12px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    border: 2px solid rgba(255, 255, 255, 0.0);
    cursor: pointer;
    flex-grow: 1;
    /* flex-shrink: 1; */

    transition: 0.3s border;
}

.followUp:last-of-type {
    margin-right: 0px;
}

.followUp.selected {
    border: 2px solid #3d68f3;
}

.check {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 16px;
    height: 16px;
    background-color: #3d68f3;
    border-radius: 8px;
}

.number {
    color: rgba(51, 51, 51, 1.0);
}

.text {
    color: rgba(51, 51, 51, 0.5);
}

.datePicker {
    display: flex;
    /* height: 40px;/ */
    padding: 0px 16px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.dateIcon {
    position: absolute;
    top: 0px;
    left: 16px;
    width: 44px;
    height: 44px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-image: url('../../assets/calendar_thick.svg');
    opacity: 0.56;
    pointer-events: none;
}

.date {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    padding-left: 44px;
    text-align: left;
    /* height: 40px; */
    /* color: rgb(51, 51, 51); */
    /* color: brown; */
    
}

.dateLeft {
    line-height: 24px;
    padding-bottom: 4px;
    margin-right: 8px;
}

.dateMiddle {
    line-height: 24px;
    padding-bottom: 8px;
    margin-left: 4px;
    margin-right: 4px;
}

.dateRight {
    line-height: 24px;
    padding-bottom: 8px;
    margin-left: 8px;
}

.credits {
    width: 100%;
    bottom: calc(52px + 20px + env(safe-area-inset-bottom) + 64px);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 16px;
}

.creditIcon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    background-image: url('../../assets/nav_coins.svg');
}

.creditText {
    margin-left: 8px;
    margin-bottom: 2px;
}

.actions {
    width: 100%;
    position: fixed;
    bottom: calc(52px + 20px + env(safe-area-inset-bottom));
    display: flex;
    box-sizing: border-box;
    margin-top: 20px;
    padding-left: 16px;
    padding-right: 16px;

    /* position: fixed; */
    /* bottom: calc(env(safe-area-inset-bottom) + 72px); */
}

.previewActions {
    /* position: fixed; */
    width: 100%;
    bottom: calc(52px + 20px + env(safe-area-inset-bottom));
    display: flex;
    box-sizing: border-box;
    margin-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 14px;
}

.cancel {
    background-color: rgba(255, 255, 255, 0.0);
    margin-left: 0px;
}

.done {
    margin-left: 0px;
    margin-right: 0px;
}

.previewActions {
    margin-left: 0px;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.page {
    display: none;
    margin-top: 24px;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.page.display {
    display: block;
}

.page.visible {
    opacity: 1.0;
    transform: scale(1.0);
}

.sendImmediately {
    padding: 0px 16px;
    color: rgba(51, 51, 51, 0.5);
}

.repeatLabel {
    margin-top: 12px;
    margin-left: 8px;
    padding: 0px 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    color: rgba(51, 51, 51, 0.5);
}

.headline {
    font-size: 18px;
    line-height: 26px;
    margin-left: 8px;
    margin-bottom: 20px;
    font-weight: 500;
    color: rgba(51, 51, 51, 1.0);
}

.contactsHeader {
    margin-top: 20px;
    margin-bottom: 12px;
    margin-left: 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(51, 51, 51, 0.7);
}

.contacts {
    overflow: scroll;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0px 4px;
    box-sizing: border-box;
}

.preview {
    display: flex;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 20px;
    color: rgba(51, 51, 51, 1.0);
    background-color: rgba(255, 255, 255, 0.8);
}

.previewInfo {
    margin-left: 10px;
    width: 100%;
}

.previewTitle {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 4px;
}

.previewNumber {
    font-size: 16px;
    font-weight: 600;
}

.previewContent {
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
}

.previewMessage {
    font-size: 15px;
    line-height: 19px;
}

.previewMedia {
    width: 40px;
    height: 40px;
    background-color: rgba(230, 230, 230, 0.7);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 8px;
    margin-left: 10px;
    flex-shrink: 0;
}

.previewTime {
    font-size: 13px;
    opacity: 0.5;
    /* font-weight: 400; */
}

.previewIcon {
    width: 40px;
    height: 40px;
    background-color: rgba(230, 230, 230, 0.7);
    align-self: center;
    flex-shrink: 0;
    border-radius: 8px;
}

/* .done {
    margin-top: 12px;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    color: var(--appearance-h1, transparent);
    background-color: var(--appearance-b1, transparent);
    text-align: center;
    cursor: pointer;
} */


.send {
    position: fixed;
    width: calc(100% - 32px);
    padding-left: 8px;
    /* padding-right: 8px; */
    box-sizing: border-box;
    /* background-color: tomato; */
    bottom: calc(env(safe-area-inset-bottom) + 72px);
}

@media (min-width: 900px) {
    .content {
        max-height: 400px;
    }

    .actions {
        bottom: calc(20px + env(safe-area-inset-bottom));
    }

    .desktopSpacer {
        height: 84px;
    }
}