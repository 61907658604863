
.CustomerEntry {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CustomerEntry h2 {
    color: rgb(51, 51, 51);
    margin-top: 120px;
    margin-bottom: 32px;
    font-size: 24px;
    text-align: center;
}

.CustomerEntry img {
    width: 100vw;
    height: auto;
    /* object-fit: cover; */
}

.CustomerEntry video {
    width: 100%;
    height: auto;
}
