
.CampaignEditor {
    display: flex;
    position: fixed;
    top: -1000px;
    left: -1000px;
    right: -1000px;
    bottom: -1000px;
    padding: 1020px;
    box-sizing: border-box;
    justify-content: start;
    align-items: center;
    /* background-color: rgba(28, 26, 31, 0.5); */
    /* background-color: rgba(15, 15, 17, 0.6); */
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    /* transform: translateY(50px); */
    transition: 0.3s opacity, 0.3s transform ease;
}

.CampaignEditor.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.addNew {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(37, 34, 43, 0.7);
    backdrop-filter: blur(24px);
    z-index: 10;
}

.box {
    width: 100px;
    height: 140px;
    background: red;
    margin-bottom: 20px;
}


.page {
    margin-top: 16px;
    display: none;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.page.display {
    display: block;
}

.page.visible {
    opacity: 1.0;
    transform: scale(1.0);
}

.content {
    display: flex;
    margin-top: 8px;
    /* min-height: 100%; */
    /* padding-bottom: 16px; */
}

.column {
    flex-grow: 1;
    width: 100%;
}

.column.desktop {
    display: none;
}

.steps {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.container {
    /* width: 100%; */
    /* padding: 20px; */
    /* padding-top: env(safe-area-inset-top); */
    /* box-sizing: border-box; */
    /* border-radius: 16px; */
    /* background-color: rgb(248, 246, 242); */
    /* background-color: rgb(248, 246, 242); */
    /* border: 2px solid rgba(51, 51, 51, 0.1); */
}

.title {

}

.description {
    margin-top: 28px;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: var(--appearance-h3, transparent);
    line-height: 24px;
    text-align: center;
}

.buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.done {
    margin-top: 12px;
    width: 100%;
    height: 50px;
    border-radius: 12px;
    font-size: 15px;
    font-weight: 600;
    line-height: 50px;
    color: var(--appearance-h1, transparent);
    background-color: var(--appearance-b1, transparent);
    text-align: center;
    cursor: pointer;
}


.messages {
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
    padding-left: 16px;
    padding-right: 16px;
    
    /* padding: 0px 20px; */
    box-sizing: border-box;
    height: calc(100vh - 144px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 78px);
    overflow: scroll;

}

.messageLine {
    position: absolute;
    left: 20px;
    top: 0px;
    width: 1px;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* z-index: 1; */
}

.keywords {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 0px 20px;
    box-sizing: border-box;
    height: calc(100vh - 120px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 102px);
    overflow: scroll;
}

.qrContainer {
    width: 200px;
    height: 200px;
}

.contacts {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 0px 20px;
    box-sizing: border-box;
    height: calc(100vh - 120px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 102px);
    overflow: scroll;
}

.addContacts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-shrink: 0;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    box-sizing: border-box;
    /* background-color: brown; */
    border: 4px dashed rgba(255, 255, 255, 0.05);
    cursor: pointer;
    transition: 0.3s opacity ease;
}

.addContacts:hover {
    opacity: 0.8;
}

.addIcon {
    /* margin: 0px auto;
    margin-bottom: 8px;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E");
    opacity: 0.9; */
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.9);
    text-align: center;
    margin-bottom: 8px;
}

.desc {
    font-size: 14px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    text-align: center;
}

.contact {
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.contactName {
    color: rgba(51, 51, 51, 0.8);
}

.contactPhone {
    color: rgba(51, 51, 51, 0.5);
}

.message {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.time {
    color: rgba(51, 51, 51, 0.4);
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 8px;
    margin-left: 8px;
}

.bubble {
    /* display: inline-block; */
    font-size: 14px;
    padding: 8px 16px;
    line-height: 22px;
    background-color: #3d68f3;
    /* background-color:rgba(255, 255, 255, 0.05); */
    color: rgb(255, 255, 255);
    border-radius: 20px;
    max-width: 260px;
    margin-bottom: 1px;
}

.mediaBubble {
    display: flex;
    font-size: 14px;
    padding: 8px 16px;
    line-height: 22px;
    background-color: rgba(255, 255, 255, 0.9);
    /* background-color:rgba(255, 255, 255, 0.05); */
    color: rgba(51, 51, 51, 1.0);
    border-radius: 20px;
    max-width: 260px;
    margin-bottom: 1px;
}

.mediaTitle {
    color: rgba(51, 51, 51, 0.8);
    margin-right: 4px;
}

.mediaIcon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    opacity: 0.8;
    flex-shrink: 0;
}

.icon {
    /* position: absolute;
    top: 0px;
    right: 0px; */
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/chat_delete.svg');
    opacity: 0.0;
    cursor: pointer;
    transition: 0.3s opacity;
}

.container:hover .icon {
    opacity: 0.5;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.addContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.configureContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: brown;
    z-index: 10;
    animation-timing-function: ease;
    animation-duration: 0.3s;
    animation-name: fade;
    transition: 0.3s opacity ease;
}

.add {
    margin-top: 20px;
    margin-bottom: 20px;
}

.qrInstruction {
    color: rgba(51, 51, 51, 0.8);
    margin-top: 20px;
    display: none;
}

@keyframes fade {
    0% {
        opacity: 0.0;
    }
    100% {
        opacity: 1.0;
    }
}

@media (min-width: 900px) {
    .column {
        width: 500px;
        /* flex-basis: 300px; */
        /* background-color: green; */
        flex-grow: 0;
        border-left: 1px solid rgba(255, 255, 255, 0.05);
    }

    .qrInstruction {
        display: block;
    }

    .column.desktop {
        flex-grow: 1;
        width: auto;
        display: block;
        border-left: none;
    }

    .contacts {
        padding-bottom: 0px;
    }

    .keywords {
        padding-bottom: 0px;
    }

    .messages {
        padding-bottom: 24px;
        height: calc(100vh - 74px);
    }
}