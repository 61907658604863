
.PopupScreen {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 24px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 51, 51, 0.6);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    /* transform: translateY(50px); */
    transition: 0.3s opacity, 0.3s transform ease;
}

.PopupScreen.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.container {
    position: relative;
    width: 100%;
    max-width: 600px;
    height: 100%;
    max-height: 600px;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: rgb(24, 26, 33);
    overflow: hidden;
}

.contentContainer {
    position: absolute;
    top: 52px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.content {
    position: relative;
    box-sizing: border-box;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding-left: 24px;
    padding-right: 24px;
}

.nav .close {
    width: 36px;
    height: 36px;
    background-image: url('../../assets../../assets/close.svg');
    cursor: pointer;
}

.nav .spacer {
    width: 36px;
    height: 36px;
}

.nav .title {
    flex-grow: 1;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    line-height: 52px;
    letter-spacing: -1px;
    text-align: center;
}
