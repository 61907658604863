
/* .Conversations {
    position: relative;
    display: flex;
} */


.topBar {
    display: flex;
    padding: 20px 20px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    margin-bottom: 36px; 
    padding-right: 8px;
    /* margin-bottom: 24px;  */
}

.topBar .add {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center 14px;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_add.svg');
    opacity: 0.7;
    cursor: pointer;
}

.topBar .title {
    flex-grow: 1;
    margin-bottom: 4px;
}

.content {
    /* height: 100vh; */
    /* padding-bottom: 16px; */
    height: 100vh;
    /* height: calc(100vh - 660px); */
    /* height: 440px; */
    /* overflow: scroll; */
    box-sizing: border-box;
    /* overflow-y: scroll; */
    /* background-color: teal; */
}

.conversations {
    padding: 0px 20px;
    margin-top: 16px;
    box-sizing: border-box;
    width: 100%;
    /* height: calc(100vh - 84px); */
    height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 164px);
    overflow: scroll;
}

.chatContainer {
    position: relative;
    display: none;
    height: calc(100vh - 84px);
    overflow-y: scroll;
}

.columns {
    display: flex;
}

.page {
    height: 100%;
}

@media (min-width: 900px) {
    .conversations {
        flex-basis: 340px;
        width: 340px;
        flex-shrink: 0;
        margin-top: 0px;
        /* padding-right: 8px; */
        box-sizing: border-box;
        height: calc(100vh - 84px);
    }

    .content {
        /* height: 100vh; */
        /* padding: ; */
    }

    .chatContainer {
        flex-grow: 1;
        display: block;
    }
}

/* 
.list {
    flex-shrink: 0;
    min-width: 300px;
    border-right: 1px solid rgb(48, 48, 55);
}

.zero {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 20px 20px;
    box-sizing: border-box;
    opacity: 0.0;
    pointer-events: none;
}

.zero.visible {
    opacity: 1.0;
    pointer-events: auto;
}

.zeroContent {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 360px;
}

.zeroTitle {    
    color: rgb(51, 51, 51);
    margin-bottom: 18px;
}

.zeroMessage {
    color: rgba(51, 51, 51, 0.7);
    text-align: center;
    margin-bottom: 24px;
}


.messages {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 0px;
    flex-grow: 1;
}

.content {
    padding: 24px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100vh - 54px - 54px);
}

.input {
    display: flex;
    padding: 0px 24px; 
    box-sizing: border-box;
    height: 54px;
    border-top: 1px solid rgb(48, 48, 55);
}

.input input {
    flex-grow: 1;
    font-size: 15px;
    font-weight: 500;
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: transparent;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}

.input button {
    font-size: 15px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    cursor: pointer;
}

.item {
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-left: 20px;
    padding-right: 24px;
    padding-bottom: 24px;
    cursor: pointer;
}

.item.selected {
    background-color: rgb(48, 48, 55);
}

.unread {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: transparent;
    margin-right: 12px;
    background-color: rgb(97, 139, 255);
    opacity: 0.0;
    transition: 0.3s opacity;
}

.unread.visible {
    opacity: 1.0;
}

.name {
    font-size: 18px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.phone {
    margin-top: 8px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.7);
}

.messageContainer {
    margin-top: 16px;
    margin-bottom: 16px;
}

.message {
    position: relative;
    margin-top: 2px;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    word-wrap: break-word;
}

.message.reverse {
    flex-direction: row-reverse;
}

.message.link {
    flex-direction: row-reverse;
    cursor: pointer;
}

.bubble {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3em;
    background-color: rgb(48, 48, 55);
    border-radius: 18px;
    max-width: 320px;
}

.attachment {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    font-size: 12px;
    cursor: pointer;
}

.message.reverse .bubble {
    background-color: rgb(97, 139, 255);
}

.message.link .bubble {
    background-color:rgb(48, 48, 55);
}

.date {
    margin-top: 8px;
    margin-left: 12px;
    margin-right: 12px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(51, 51, 51, 0.5);
    text-align: right;
}

@media (min-width: 800px) {
    .Conversations {
        min-height: calc(100vh - 52px);
    }

    .content {
        max-height: calc(100vh - 52px - 54px);
    }
} */
