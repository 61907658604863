
.MediaManager {
    /* padding-left: 20px;
    padding-right: 20px; */
}

.page {
    margin-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    display: none;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.page.display {
    display: block;
}

.page.visible {
    opacity: 1.0;
    transform: scale(1.0);
}

.addContacts {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 16px;
    flex-shrink: 0;
    width: 100%;
    height: 200px;
    border-radius: 12px;
    box-sizing: border-box;
    /* background-color: brown; */
    border: 4px dashed rgba(255, 255, 255, 0.05);
    cursor: pointer;
    transition: 0.3s opacity ease;
}

.addContacts:hover {
    opacity: 0.8;
}

.addContacts input {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    opacity: 0.0;
    cursor: pointer;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
    text-align: center;
    margin-bottom: 8px;
}

.desc {
    font-size: 14px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    text-align: center;
}

.progress {
    width: 100%;
    height: 40px;
}

.banner {
    border-radius: 20px;
    padding: 12px;
    box-sizing: border-box;
    color: rgba(51, 51, 51, 1.0);
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}

.progress {
    margin-bottom: 20px;
}

.progressLabel {
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    color: rgb(51, 51, 51);
}

.progressBar {
    width: 0%;
    height: 4px;
    border-radius: 2px;
    background-color: rgb(61, 104, 243);
    transition: 0.3s width ease;
}
