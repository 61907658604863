.subheading {
    font-size: 18px;
    font-weight: bold;
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
}
.deleteButton {
    cursor: pointer;
    color: rgb(51, 51, 51);
    background-color: red;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 12px 16px;
    font-weight: normal;
}
.medias{
    width: 250px;
    height: 250px;
}