
.SplitView {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.content {
    display: flex;
}

.render {
    position: relative;
    width: 100%;
    background-color: aqua;
}
