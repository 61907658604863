
.SectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    letter-spacing: -0.5px;
    vertical-align: middle;
}

.title {
    flex-grow: 1;
}

.SectionTitle a {
    color: rgb(51, 51, 51);
    margin-right: 16px;
    cursor: pointer;
}

@media (min-width: 800px) {
    .SectionTitle {
        margin-bottom: 32px;
        font-size: 28px;
        font-weight: 400;
        letter-spacing: -1px;
    }
}