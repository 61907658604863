
.Collection {
    margin-top: 32px;
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px 24px;
}

@media (min-width: 800px) {
    .Collection.gridLayout {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .Collection.listLayout {
        grid-template-columns: 1fr;
    }
}
