
.ManageSubscriptionsModal {
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 20px;
    box-sizing: border-box;
    justify-content: start;
    padding-top: calc(env(safe-area-inset-top) + 64px);
    align-items: flex-start;
    /* background-color: rgba(248, 246, 242, 0.5); */
    background-color: rgba(15, 15, 17, 0.6);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
    /* transform: translateY(50px); */
    transition: 0.3s opacity, 0.3s transform ease;
}

.ManageSubscriptionsModal.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateY(0px);
}

.section {
    display: flex;
    color: rgba(51, 51, 51, 0.3);
    margin-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    /* padding-top: 12px;
    padding-bottom: 12px; */
}

.section .label {
    flex-grow: 1;
    line-height: 32px;
}

.section .add {
    width: 32px;
    height: 32px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_add.svg');
    opacity: 0.5;
    cursor: pointer;
}

.box {
    width: 100px;
    height: 140px;
    background: red;
    margin-bottom: 20px;
}

.content {
    display: grid;
    grid-template-columns: 1fr;
    /* padding-left: 16px;
    /* padding-right: 16px; */
    padding: 0px 16px;
    box-sizing: border-box;
    /* padding-bottom: 16px; */
}

.container {
    margin-top: 8px;
    /* padding-top: 24px; */

    /* /* padding-left: 16px; */
    /* padding-right: 16px; */
    /* padding: 16px 16px; */
    /* box-sizing: border-box; */
    /* padding-bottom: 16px; */
}

.title {

}

.options {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.search {
    padding: 0px 20px;
}

.input {
    margin-bottom: 32px;
}

.progress {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    box-sizing: border-box;
    padding: 24px;
    background-color: rgba(248, 246, 242, 0.5);
    opacity: 0.0;
    pointer-events: none;
    z-index: 10;
    transition: 0.3s opacity ease;
}

.progress.visible {
    opacity: 1.0;
    pointer-events: auto;
}

.progressMessage {
    font-size: 15px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
}


.package {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 18px 22px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 2px solid rgba(255, 255, 255, 0.9);
    cursor: pointer;
    margin-bottom: 20px;
}

.package.selected {
    border: 2px solid #3d68f3;
}

.number {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 18px 22px;
    box-sizing: border-box;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 2px solid rgba(255, 255, 255, 0.9);
    cursor: pointer;
    margin-bottom: 20px;
}

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.title {
    color: rgba(51, 51, 51, 1.0);
    margin-bottom: 8px;
}

.description {
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 4px;
}

.arrow {
    /* width: 24px; */
    color: #3d68f3;
}

.delete {
    /* width: 24px; */
    width: 32px;
    height: 32px;
    margin-top: 4px;
    background-size: 32px 32px;
    /* background-position: center; */
    background-position: 6px 2px;
    /* background-position-x: 200%; */
    background-repeat: no-repeat;
    background-image: url('../../assets/item_delete.svg');
    color: #3d68f3;
    /* background: green; */
    opacity: 0.5;
}

.AccountItem:hover .arrow {
    transform: translateX(0px);
    opacity: 0.7;
}


.billingDialog {
    position: fixed;
    top: 0px;
    left: 0px;
}

.cancel {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    cursor: pointer;
    margin-top: 24px;
    margin-bottom: 20px;
}

.page {
    display: none;
    margin-top: 24px;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.page.display {
    display: block;
}

.page.visible {
    opacity: 1.0;
    transform: scale(1.0);
    /* display: block; */
}

@media (min-width: 900px) {
    .content {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 16px;
    }
}