
.InsufficientCreditsWall {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgb(248, 246, 242);
    z-index: 1000;
    opacity: 0.0;
    pointer-events: none;
}

.InsufficientCreditsWall.visible {
    opacity: 1.0;
    pointer-events: auto;
}
