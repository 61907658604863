
.Modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    pointer-events: none;
    /* background-color: rgb(248, 246, 242, 0.5); */
    background-color: rgba(128, 128, 128, 0.5);
    /* backdrop-filter: blur(24px); */
    z-index: 10;
    overflow: hidden;
    opacity: 0.0;
    transition: 0.3s opacity ease;
}

.Modal.visible {
    opacity: 1.0;
    transform: translateX(0px);
    pointer-events: auto;
}

.container {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: rgb(248, 246, 242);
    /* backdrop-filter: blur(24px); */
    /* border-left: 1px solid rgba(37, 34, 43, 1.0); */
    overflow: hidden;
    transform: translateX(40px);
    transition: 0.4s transform ease;
}

.Modal.visible .container {
    transform: translateX(0px);
}

.content {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    padding-top: calc(env(safe-area-inset-top) + 64px);
    padding-bottom: calc(72px + 20px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    transition: 0.3s opacity ease;
}

.content.hidden {
    opacity: 0.0;
}

.secondaryContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    padding-top: calc(env(safe-area-inset-top) + 64px);
    padding-bottom: calc(56px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    left: 100%;
    opacity: 0.0;
    pointer-events: none;
    transform: translateX(calc(-100% + 40px));
    transition: 0.3s opacity ease, 0.4s transform ease;
}

.secondaryContent.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateX(-100%);
}

.confirmContent {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    padding-top: calc(env(safe-area-inset-top) + 64px);
    padding-bottom: calc(56px + env(safe-area-inset-bottom));
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    left: 100%;
    opacity: 0.0;
    pointer-events: none;
    transform: translateX(calc(-100% + 40px));
    transition: 0.3s opacity ease, 0.4s transform ease;
    background-color: rgb(248, 246, 242);
}

.confirmContent.visible {
    opacity: 1.0;
    pointer-events: auto;
    transform: translateX(-100%);
}

.topBar {
    position: relative;
    display: flex;
    box-sizing: border-box;
    padding-top: calc(8px + env(safe-area-inset-top));
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 8px;
    z-index: 1;
    background-color: rgba(248, 246, 242, 0.5);
    backdrop-filter: blur(24px);
    /* transition: 0.3s backdrop-filter; */
}

.topBar .main {
    width: 100%;
    display: flex;
    opacity: 1.0;
    transition: 0.3s opacity ease;
}

.topBar .main.hidden {
    opacity: 0.0;
    pointer-events: none;
}

.topBar.scrolled {
    /* background-color: rgba(248, 246, 242, 1.0); */
    /* backdrop-filter: blur(24px); */
    /* background-color: royalblue; */
}

.topBar .back {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: 20px;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_close.svg');
    opacity: 0.7;
    cursor: pointer;
}

.topBar .title {
    flex-grow: 1;
    margin-left: 4px;
    line-height: 50px;
    z-index: 1;
}

.secondaryTop {
    position: absolute;
    top: 0px;
    left: 0px;
}

.secondaryTop {
    display: flex;
    box-sizing: border-box;
    padding-top: calc(8px + env(safe-area-inset-top));
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    opacity: 0.0;
    background-color: rgba(37, 34, 43, 0.0);
    transition: 0.3s background-color, 0.3s backdrop-filter, 0.3s opacity ease;
}

.confirmTop {
    position: absolute;
    top: 0px;
    left: 0px;
}

.confirmTop {
    display: flex;
    box-sizing: border-box;
    padding-top: calc(8px + env(safe-area-inset-top));
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    opacity: 0.0;
    background-color: rgba(37, 34, 43, 0.0);
    background-color: rgb(248, 246, 242);
    z-index: 1;
    width: 100%;
    pointer-events: none;
    transition: 0.3s background-color, 0.3s backdrop-filter, 0.3s opacity ease;
}

.secondaryTop.visible  {
    opacity: 1.0;
}

.confirmTop.visible  {
    opacity: 1.0;
    pointer-events: auto;
}

.secondaryTop .back {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_back.svg');
    opacity: 0.7;
    cursor: pointer;
}

.confirmTop .back {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_back.svg');
    opacity: 0.7;
    cursor: pointer;
}

@media (min-width: 900px) {
    /* .Modal {
        left: auto;
        right: 0px;
        max-width: 600px;
    } */

    .Modal {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .container {
        margin-top: 80px;
        right: auto;
        left: auto;
        max-width: 500px;
        flex-shrink: 1;
        height: auto;
        border-radius: 20px;
        transform: translateY(40px);
    }

    .content {
        position: relative;
        padding-bottom: 20px;
        padding-top: calc(env(safe-area-inset-top));
    }

    .secondaryContent {
        top: 66px;
        padding-bottom: 72px;
        padding-top: calc(env(safe-area-inset-top));
    }

    .confirmContent {
        top: 66px;
        padding-bottom: 72px;
        padding-top: calc(env(safe-area-inset-top));
    }
}