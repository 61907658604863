

.GoingItem {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding-top: 16px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 16px;
    box-sizing: border-box;
}

.info {
    width: 100%;
}

.titleContainer {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
}

.date {
    font-size: 13px;
    color: rgba(51, 51, 51, 0.5);
}

.check {
    width: 36px;
    height: 36px;
    background-image: url('../../assets/check.svg');
    opacity: 0.0;
    transition: 0.3s opacity ease;
}

.check.selected {
    opacity: 0.5;
}

.message {
    display: block;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.5);
    max-height: 44px;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}

.unread { 
    margin-right: 16px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #3d68f3;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.unread.visible {
    opacity: 1.0;
    transform: scale(1.0);
}
