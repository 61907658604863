
.Empty {
    
}

.Empty {
    display: none;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 20px 20px;
    box-sizing: border-box;
}

.Empty.visible {
    display: block;
}

.content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 320px;
}

.icon {    
    width: 64px;
    height: 64px;
    background-size: 64px 64px;
    background-position: center;
    background-repeat: no-repeat;
    color: rgb(51, 51, 51);
    margin-bottom: 18px;
    opacity: 0.7;
}

.message {
    color: rgba(51, 51, 51, 0.7);
    text-align: center;
    margin-bottom: 24px;
}
