
.HeaderButton {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    /* justify-content: space-between; */
    width: 100%;
    cursor: pointer;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
/* 
.info {
    display: flex;
} */

.titleContainer {
    display: flex;
    align-items: center;
    /* margin-bottom: 4px; */
}

.title {
    color: rgba(51, 51, 51, 1.0);
    line-height: 20px;
}

.description {
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 4px;
}

.company {
    color: rgba(51, 51, 51, 0.5);
    margin-left: 8px;
}

.icon {
    /* position: absolute;
    top: 0px;
    right: 0px; */
    margin-right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E");
    opacity: 0.9;
    cursor: pointer;
    transition: 0.3s opacity;
}

.upload {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' enable-background='new 0 0 24 24' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cg%3E%3Crect fill='none' height='24' width='24'/%3E%3C/g%3E%3Cg%3E%3Cpath d='M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z'/%3E%3C/g%3E%3C/svg%3E");
}

.group {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23333333'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M9 13.75c-2.34 0-7 1.17-7 3.5V19h14v-1.75c0-2.33-4.66-3.5-7-3.5zM4.34 17c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H4.34zM9 12c1.93 0 3.5-1.57 3.5-3.5S10.93 5 9 5 5.5 6.57 5.5 8.5 7.07 12 9 12zm0-5c.83 0 1.5.67 1.5 1.5S9.83 10 9 10s-1.5-.67-1.5-1.5S8.17 7 9 7zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V19h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM15 12c1.93 0 3.5-1.57 3.5-3.5S16.93 5 15 5c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z'/%3E%3C/svg%3E");
}

.KeywordItem:hover .icon {
    /* opacity: 0.7; */
}

.KeywordItem input {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    opacity: 0.0;
    cursor: pointer;
}

.noMobile {
    display: none !important;
    background-color: rgba(255, 255, 255, 0.822);
}

@media (min-width: 900px) {
    .noMobile {
        display: block !important;
    }
}