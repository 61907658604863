
.Sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 200px;
    height: 100vh;
    border-right: 1px solid rgb(48, 48, 55);
    background-color: rgb(24, 26, 33);
    box-sizing: border-box;
    z-index: 10;
    transform: translateX(-200px);
    transition: 0.3s transform;
}

.Sidebar.active {
    transform: translateX(0px);
}

.branding {
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    height: 54px;
    font-size: 18px;
    font-weight: 600;
    line-height: 54px;
    color: rgb(51, 51, 51);
    background-color: rgb(20, 210, 210);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -0.5px;
}

.navLink {
    display: flex;
    width: 100%;
    height: 52px;
    font-size: 15px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    line-height: 28px;
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    box-sizing: border-box;
    opacity: 0.7;
}

.navLink.active {
    font-weight: 700;
    opacity: 1.0;
    color: rgba(51, 51, 51, 1.0);
}

.icon {
    margin-top: 2px;
    margin-right: 16px;
    margin-bottom: 2px;
    letter-spacing: 0px;
}

.bottomed {
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-bottom: none;
}

@media (min-width: 800px) {
    .Sidebar {
        transform: translateX(0px);
    }

    .branding {
        height: 64px;
        font-size: 24px;
        line-height: 64px;
    }
}
