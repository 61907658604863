
.topBar {
    display: flex;
    padding: 20px 20px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    margin-bottom: 36px; 
    padding-right: 8px;
}

.topBar .add {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center 14px;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_add.svg');
    opacity: 0.7;
    cursor: pointer;
}

.topBar .title {
    flex-grow: 1;
    margin-bottom: 4px;
}

.content {
    padding-bottom: 16px;
}

.lists {
    padding: 0px 20px;
    /* margin-top: 16px; */
    box-sizing: border-box;
}
