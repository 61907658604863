
.topBar {
    display: flex;
    padding: 20px 20px;
    box-sizing: border-box;
    height: 48px;
    line-height: 48px;
    margin-bottom: 36px; 
    padding-right: 8px;
    /* margin-bottom: 24px;  */
}

.topBar .add {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center 14px;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_add.svg');
    opacity: 0.7;
    cursor: pointer;
}

.topBar .title {
    flex-grow: 1;
    margin-bottom: 4px;
}

.content {
    /* padding-bottom: 16px; */
    height: 100vh;
}

.campaigns {
    padding: 0px 20px;
    margin-top: 16px;
    box-sizing: border-box;
    /* background-color: teal; */
    height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 132px);
    padding-bottom: 56px;
    overflow-y: scroll;
}

.search {
    display: block;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: rgba(51, 51, 51, 1.0);
    background-color: transparent;
    margin-bottom: 20px;
}

.search::placeholder {
    color: rgba(51, 51, 51, 0.5);
}

@media (min-width: 900px) {
    .campaigns {
        height: calc(100vh - 132px);
    }
    
}