
.AnalyticsItem {
    display: flex;
    width: 100%;
    height: 40px;
    font-size: 16px;
    color: rgb(51, 51, 51);
    font-weight: 500;
    line-height: 44px;
}

.column {
    flex-grow: 1;
    flex-basis: 0;
}