

.CampaignItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    /* background-color: brown; */
    padding-top: 16px;
    padding-bottom: 16px;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.05); */
    border-radius: 20px;
    padding: 20px 20px;
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.9);
}

.indicators {
    display: flex;
    align-items: center;
}

.indicator {
    width: 24px;
    height: 24px;
    background-image: url('../../assets/arrow_right.svg');
    opacity: 0.5;
    /* margin-right: 12px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #61E294; */
}




.titleContainer {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    margin-bottom: 10px;
}

.company {
    color: rgba(51, 51, 51, 0.5);
    /* margin-top: 4px; */
    /* margin-bottom: 4px; */
    margin-left: 12px;
    line-height: 14px;
    max-height: 14px;
    overflow: hidden;
}

.title {
    color: rgba(51, 51, 51, 1.0);
    font-size: 17px;
    line-height: 17px;
    max-height: 17px;
    /* overflow: hidden; */
}

.stats {
    display: flex;
}

.stat {
    display: flex;
    margin-right: 24px;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: 10px 10px;
    background-position: center;
    margin-right: 8px;
    /* background-color: brown; */
}

.number {
    color: rgba(51, 51, 51, 0.6);
    margin-bottom: 8px;
}

.category {
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 2px;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.9;
}

.text {
    color: rgba(51, 51, 51, 1.0);
}

.delete {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/chat_delete.svg');
    opacity: 0.0;
    cursor: pointer;
    transition: 0.3s opacity;
}

.CampaignItem:hover .delete {
    opacity: 0.5;
}

/*
we gonna keep the original shit here until we can revamp these

.CampaignItem {
    padding-top: 2px;
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 120px;
}

.title {
    margin-bottom: 28px;
    font-size: 22px;
    font-weight: 500;
    color: rgb(51, 51, 51);
    line-height: 26px;
}

.label {
    font-size: 12px;
    font-weight: 600;
    color: rgba(51, 51, 51, 0.5);
    text-transform: uppercase;
    letter-spacing: 1px;
}

.phone {
    margin-top: 8px;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.keyword {
    margin-top: 24px;
    font-size: 15px;
    font-weight: 500;
    color: rgb(51, 51, 51);
}

.link {
    display: block;
    margin-top: 36px;
    font-size: 13px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 0.3s opacity;
}
.deleteButton {
    cursor: pointer;
    color: rgb(51, 51, 51);
    width: 130px;
    text-align: center;
    margin-top: 12px;
    background-color: rgb(158, 0, 0);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 6px 8px;
    font-weight: normal;
}

.link:hover {
    opacity: 0.7;
}

@media (min-width: 800px) {
    .CampaignItem {
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        min-height: 200px;
    }
}
*/