
.MediaAnalyticsItem {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.05); */
}

.title {
    color: rgba(51, 51, 51, 1.0);
    margin-bottom: 2px;
}

.description {
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 2px;
}

.campaign {
    color: rgba(51, 51, 51, 0.7);
    margin-bottom: 2px;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 20px;
    background-size: 24px 24px;
    opacity: 0.8;
}
