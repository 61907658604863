
.header {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    padding: 24px;
    background-color: rgb(48, 48, 55);
    border-radius: 12px;
    box-sizing: border-box;
    z-index: 1;
    transform: translateY(-100%);
    transition: 0.3s transform ease;
}

.header.addVisible {
    transform: translateY(0px);
}

.form {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
}

.title {
    margin-top: 8px;
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(51, 51, 51);
}

.textField {
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
    border-radius: 8px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: rgb(51, 51, 51);
    box-sizing: border-box;
}

.textField:focus {
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.button {
    display: inline-block;
    margin-top: 20px;
    margin-right: 12px;
    padding-top: 4px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 4px;
    font-size: 15px;
    font-weight: 600;
    border-radius: 8px;
    background-color: transparent;
    color: rgba(51, 51, 51, 0.7);
    text-align: center;
    cursor: pointer;
    transition: 0.3s border-color, 0.3s color;
}

.submit {
    color: rgba(51, 51, 51, 1.0);
    pointer-events: auto;
}

.add {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: rgb(51, 51, 51, 0.7);
    line-height: 26px;
    box-sizing: border-box;
    cursor: pointer;
}

.list {
    padding-left: 24px;
    padding-right: 24px;
}