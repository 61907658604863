
.TabBar {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    height: 56px;
}

.tooltip {
    display: flex;
    position: absolute;
    top: 0px;
    left: 64px;
    flex-grow: 1;
    /* height: 32px; */
    line-height: 32px;
    text-align: left;
    background-color: rgb(248, 246, 242);
    border-radius: 10px;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.1);
    padding: 4px 12px;
    white-space: nowrap;
    box-sizing: border-box;
    opacity: 0.0;
    pointer-events: none;
    transition: 0.3s opacity;
}

.tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    text-align: center;
    line-height: 52px;
    cursor: pointer;
    opacity: 1.0;
    color: rgb(51, 51, 51);
    flex-shrink: 0;
    /* text-shadow: 0 0 5px rgba(255, 255, 255, 0.3); */
    transition: 0.3s opacity;
}

.tab.selected .icon {
    opacity: 1.0;
}

.icon {
    width: 36px;
    height: 36px;
    background-size: 30px 30px;
    /* background-size: 28px 28px; */
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 5px;
    opacity: 0.7;
    transition: 0.3s opacity;
}

.tabHighlight {
    position: absolute;
    top: 74px;
    left: 0px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    /* height: 8px; */
    transform: translateY(54px);
    z-index: -1;
    opacity: 0.0;
    transition: 0.3s opacity ease, 0.4s transform ease;
}

.tab.selected .tabHighlight {
    opacity: 1.0;
    transform: translateY(0px);
}

.tabHighlight.selected {
    opacity: 1.0;
}

.tabHighlight .box {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 36px;
    height: 36px;
    border-radius: 24px;
    flex-shrink: 0;
    /* background-color: rgba(90, 204, 137, 1.0); */
    background-color: #4670fc;
    /* box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.1); */
    /* filter: blur(2px); */
}

.badge {
    position: absolute;
    top: 4px;
    right: 24px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: rgb(230, 115, 73);
}

@media (min-width: 900px) {
    .TabBar {
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 48px;
    }

    .tab {
        flex-direction: row;
        margin-bottom: 20px;
    }

    .tabHighlight {
        width: auto;
        top: auto;
        transform: translateX(-54px);
        left: 20px;
    }

    .tab.selected .tabHighlight {
        opacity: 1.0;
        transform: translateX(-48px);
    }

    .tab:hover .tooltip {
        opacity: 1.0;
    }
}