
.AddFooter {
    padding-top: 2px;
    padding-bottom: 28px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    min-height: 120px;
    cursor: pointer;
}

.plus {
    margin-top: 16px;
    font-size: 48px;
    font-weight: 300;
    color: rgb(51, 51, 51);
    text-align: center;
    cursor: pointer;
}

.addNew {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(51, 51, 51, 1.0);
    text-align: center;
    cursor: pointer;
}

@media (min-width: 800px) {
    .AddFooter {
        padding: 24px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 8px;
        min-height: 200px;
    }

    .plus {
        margin-top: 48px;
    }
}