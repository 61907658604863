
.Collection {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
}

.Collection.listLayout {
    gap: 0;
}

.Collection.listLayoutNoGrid {
    gap: 0;
    display: block;
}

.Collection.gridLayout {
    grid-template-columns: 1fr 1fr;
}

.Collection.itemLayout {
    grid-template-columns: 1fr;
    gap: 16px;
}

@media (min-width: 900px) {
    .Collection.gridLayout {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .Collection.itemLayout {
        grid-template-columns: 1fr 1fr;
    }

    .Collection.listLayout {
        grid-template-columns: 1fr;
    }
}

@media (min-width: 1200px) {
    .Collection.itemLayout {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
