
.AccountItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 8px;
    box-sizing: border-box;
    /* border-radius: 16px; */
    /* background-color: rgb(248, 246, 242); */
    cursor: pointer;
}

.info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.title {
    color: rgba(51, 51, 51, 1.0);
    margin-bottom: 12px;
}

.description {
    color: rgba(51, 51, 51, 0.3);
    margin-bottom: 8px;
}

.stat {
    margin-bottom: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 27px;
    font-weight: 400;
}

.category {
    color: rgba(51, 51, 51, 0.3);
    margin-bottom: 8px;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: 100% 100%;
    margin-bottom: 16px;
    opacity: 0.7;
}

.arrow {
    width: 24px;
    height: 24px;
    background-image: url('../../assets/arrow_right.svg');
    opacity: 0.5;
    transform: translateX(-8px);
    transition: 0.3s transform ease, 0.3s opacity ease;
}

.AccountItem:hover .arrow {
    transform: translateX(0px);
    opacity: 0.7;
}