
.Modal {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 9;
    opacity: 0.0;
    /* background-color: rgb(248, 246, 242); */
    background-color: rgb(248, 246, 242);
    transform: translateX(40px);
    transition: 0.3s opacity ease, 0.4s transform ease;
}

.Modal.visible {
    transform: translateX(0px);
    opacity: 1.0;
    pointer-events: auto;
}

.Modal.aboveTabs {
    z-index: 1000;
}

.content {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    padding-top: calc(66px + env(safe-area-inset-top));
    padding-bottom: calc(72px + env(safe-area-inset-bottom));
    box-sizing: border-box;
    /* overflow-x: hidden; */
    /* overflow-y: scroll; */
    /* overflow: scroll; */
}

.content.scrolls {
    overflow-y: scroll;
}

.topBar {
    display: flex;
    box-sizing: border-box;
    padding-top: calc(8px + env(safe-area-inset-top));
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    background-color: rgba(37, 34, 43, 0.0);
    transition: 0.3s background-color, 0.3s backdrop-filter;
}

.topBar.scrolled {
    /* background-color: rgb(255, 255, 255); */
    /* backdrop-filter: blur(24px); */
}

.topBar .back {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_back.svg');
    opacity: 0.7;
    cursor: pointer;
}

.topBar .title {
    flex-grow: 1;
    margin-left: 4px;
    line-height: 50px;
    z-index: 1;
}

.topBar .add {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center 14px;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_add.svg');
    opacity: 0.7;
    cursor: pointer;
}

.topBar .accessory {
    width: 48px;
    height: 48px;
    background-size: 24px 24px;
    background-position: center 14px;
    background-repeat: no-repeat;
    background-image: url('../../assets/nav_qr.svg');
    opacity: 0.7;
    cursor: pointer;
}

.content {
    /* padding-bottom: 16px; */
}

.campaigns {
    padding: 20px 20px;
    box-sizing: border-box;
}

@media (min-width: 900px) {
    .Modal {
        padding-left: 84px;
    }

    .Modal.mobileOnly {
        display: none;
    }

    .Modal.noIndent {
        padding-left: 0px;
    }

    .Modal.noIndent .content {
        padding-left: 0px;
    }

    .content {
        padding-left: 84px;
        padding-top: 64px;
        height: calc(100% - 0px);
        padding-bottom: 0px;
        overflow: hidden;
    }
}