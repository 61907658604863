
.ContactListItem {
    display: flex;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    /* background-color: brown; */
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.indicators {
    display: flex;
    align-items: center;
}

.indicator {
    width: 24px;
    height: 24px;
    background-image: url('../../assets/arrow_right.svg');
    opacity: 0.5;
    /* margin-right: 12px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #61E294; */
}




.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.company {
    color: rgba(51, 51, 51, 0.5);
    margin-left: 12px;
}


.title {
    color: rgba(51, 51, 51, 1.0);
}

.stats {
    display: flex;
}

.stat {
    display: flex;
    margin-right: 24px;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: 10px 10px;
    background-position: center;
    margin-right: 8px;
    /* background-color: brown; */
}

.number {
    color: rgba(51, 51, 51, 0.6);
    margin-bottom: 8px;
}

.category {
    color: rgba(51, 51, 51, 0.3);
    margin-bottom: 2px;
}

.icon {
    width: 24px;
    height: 24px;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.5;
}

.text {
    color: rgba(51, 51, 51, 0.5);
}

.delete {
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/chat_delete.svg');
    opacity: 0.0;
    cursor: pointer;
    transition: 0.3s opacity;
}

.ContactListItem:hover .delete {
    opacity: 0.5;
}
