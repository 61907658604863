

.ImportContactItem {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
    box-sizing: border-box;
}

.info {
    width: 100%;
}

.titleContainer {
    display: flex;
    width: 100%;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
}

.date {
    font-size: 13px;
    color: rgba(51, 51, 51, 0.5);
}

.check {
    width: 36px;
    height: 36px;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='48px' height='48px' viewBox='0 0 48 48' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EArtboard%3C/title%3E%3Cg id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' transform='translate(15.000000, 17.000000)' fill='%23333333'%3E%3Crect id='Rectangle' transform='translate(4.000000, 10.250000) rotate(45.000000) translate(-4.000000, -10.250000) ' x='0' y='9' width='8' height='2.5'%3E%3C/rect%3E%3Crect id='Rectangle-Copy' transform='translate(11.000000, 7.250000) rotate(-45.000000) translate(-11.000000, -7.250000) ' x='3' y='6' width='16' height='2.5'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    opacity: 0.0;
    transition: 0.3s opacity ease;
}

.check.selected {
    opacity: 0.5;
}

.message {
    display: block;
    font-size: 14px;
    color: rgba(51, 51, 51, 0.5);
    max-height: 44px;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    word-wrap: break-word;
}

.unread { 
    margin-right: 16px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: #3d68f3;
    opacity: 0.0;
    transform: scale(0.8);
    transition: 0.3s opacity ease, 0.3s transform ease;
}

.unread.visible {
    opacity: 1.0;
    transform: scale(1.0);
}
