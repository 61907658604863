
.KeywordItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
/* 
.info {
    display: flex;
} */

.titleContainer {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    line-height-step: 17px;
}

.title {
    color: rgba(51, 51, 51, 1.0);
}

.description {
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 4px;
}

.company {
    color: rgba(51, 51, 51, 0.5);
    margin-left: 8px;
}

.icon {
    /* position: absolute;
    top: 0px;
    right: 0px; */
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../assets/chat_delete.svg');
    opacity: 0.0;
    cursor: pointer;
    transition: 0.3s opacity;
}

.KeywordItem:hover .icon {
    opacity: 0.5;
}
