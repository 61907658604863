
.App {
    font-family: 'Poppins', sans-serif;
}

.App * {
    scrollbar-width: none;
}

.App input {
    font-family: 'Poppins', sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    box-sizing: border-box;
    border-radius: 0;
}

.App textarea {
    font-family: 'Poppins', sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    box-sizing: border-box;
    border-radius: 0;
}

.App ::-webkit-scrollbar {
    display: none;
}

.Routes {
    min-height: calc(100vh - 52px - env(safe-area-inset-bottom));
}

.content {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    min-height: calc(100vh - 52px - env(safe-area-inset-bottom));
    padding-top: env(safe-area-inset-top);
    padding-bottom: calc(56px + env(safe-area-inset-bottom));
}

.routeContainer {
    position: relative;
    min-height: calc(100vh - 52px - env(safe-area-inset-bottom));
    overflow: hidden;
    box-sizing: border-box;
}

.App select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    box-sizing: border-box;
}

.noPadding {
    padding: 0px !important;
}

.routeContent {
    display: none;
}

.routeContent.visible {
    display: block;
}

.tabContainer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: calc(52px + env(safe-area-inset-bottom));
    z-index: 1000;
    /* background-color: rgba(37, 34, 43, 0.7); */
    background-color: rgba(248, 246, 242, 0.5);
    backdrop-filter: blur(24px);
    
}

textarea {
    resize: none;
}

.splash {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 0.0;
    pointer-events: none;
    transition: 0.3s opacity ease;
    background-color: rgb(248, 246, 242);
    z-index: 10000000;
}

.splash.visible {
    opacity: 0.0;
    pointer-events: auto;
}

.uploading {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    opacity: 0.0;
    pointer-events: none;
    transition: 0.3s opacity ease;
    background-color: rgba(128, 128, 128, 0.3);
    z-index: 10000000;
}

.uploading.visible {
    opacity: 1.0;
    pointer-events: auto;
}

.uploadingMessage {
    font-size: 15px;
    font-weight: 600;
    color: rgba(51, 51, 51, 1.0);
}

:global(.t1) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: rgba(51, 51, 51, 0.7);
}

:global(.t2) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: rgba(51, 51, 51, 0.7);
}

:global(.h1) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
}

:global(.h2) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 21px;
    line-height: 26px;
    color: rgba(51, 51, 51, 0.7);
}

:global(.h3) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(51, 51, 51, 0.7);
}

:global(.s1) {
    font-family: 'Merriweather', sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.7);
}

:global(.b1) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 13px;
}

:global(.button) {
    width: 100%;
    height: 48px;
    margin: 0px 8px;
    padding: 0px 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    color: rgb(255, 255, 255);
    text-align: center;
    background: #3d68f3;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s opacity ease;
}

:global(.cta) {
    height: 48px;
    width: 100%;
    margin: 0px 8px;
    padding: 0px 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 15px;
    line-height: 48px;
    color: rgb(255, 255, 255);
    text-align: center;
    background: #4670fc;
    border-radius: 12px;
    box-sizing: border-box;
    cursor: pointer;
    transition: 0.3s opacity ease;
}

:global(.disabled) {
    pointer-events: none;
    opacity: 0.7;
}

:global(.cancel) {
    width: 100%;
    height: 48px;
    margin: 0px 8px;
    padding: 0px 24px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 48px;
    color: rgb(51, 51, 51);
    text-align: center;
    /* background: rgba(51, 51, 51, 0.3); */
    background-color: rgba(255, 255, 255, 0.0);
    border-radius: 12px;
    cursor: pointer;
    transition: 0.3s opacity ease;
}

:global(.it1) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
}

:global(.it2) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(51, 51, 51, 0.7);
}

:global(.ita2) {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.7);
}

:global(.ip2) {
    display: block;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    line-height: 44px;
    background: none;
    outline: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    color: rgb(51, 51, 51);
    margin-bottom: 4px;
    transition: 0.3s border;
}

:global(.ip2::placeholder) {
    color: rgba(51, 51, 51, 0.3);
}

:global(.ip2:focus) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

:global(.button:hover) {
    opacity: 0.8;
}

:global(.cancel:hover) {
    opacity: 0.7;
}

:global(.cta:hover) {
    opacity: 0.8;
}

@media (min-width: 900px) {
    .tabContainer {
        left: 0px;
        width: 84px;
        height: 100%;
        backdrop-filter: none;
    }

    .Routes {
        min-height: 100vh;
    }

    .content {
        min-height: 100vh;
        padding-bottom: 0px;
        padding-left: 84px;
    }

    .routeContainer {
        min-height: 100vh;
    }
}
