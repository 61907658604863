
.DropZone {
    margin-top: 32px;
    margin-bottom: 32px;
    width: 100%;
    height: 100px;
    border: 2px dashed rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: rgb(51, 51, 51, 0.7);
    line-height: 26px;
}

.text {
    margin-left: 8px;
}
